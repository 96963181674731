<style>
.settings-collapse {
	margin-bottom: 1rem;
}

.hard-border {
	border-radius: 0 !important;
}

@media (max-width: 1024px) {
	.sm-spacer {
		margin-top: .5rem;
	}
}

.save-btn.btn-success {
	box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
	transform: scale(1);
	animation: pulse 2s infinite;
}

@keyframes pulse {
	0% {
		box-shadow: 0 0 0 0 #34c38f;
	}

	100% {
		box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
	}
}

@keyframes pulsePrimary {
	0% {
		box-shadow: 0 0 0 0 #4285F4;
	}

	100% {
		box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
	}
}

@keyframes pulseDanger {
	0% {
		box-shadow: 0 0 0 0 #f46a6a;
	}

	100% {
		box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
	}
}
</style>
<script>
import {VclCode} from 'vue-content-loading';
import {HalfCircleSpinner} from 'epic-spinners';
import {countries, timezones} from './countryData';

import {get_acsrf_token} from "@/methods";
import {mapGetters} from "vuex";
import {Game, OmegaAction, StreamEvent, TriggerAttributes, TriggerCondition} from "@/enums";

export default {
	props: ['server_id', 'options'],
	components: {
		VclCode,
		HalfCircleSpinner
	},
	computed: {
		...mapGetters([
			'getDTLocale'
		]),
	},
	methods: {
		handleError: function (error) {
			console.log(`[ERROR] ${error}`);
			this.error = true;
		},
		getConfiguration: function () {
			fetch(process.env.VUE_APP_ROOT_API + `v1/server/${this.server_id}/radar`, {credentials: 'include'})
					.then(response => {
						if (response.ok) {
							return response.json();
						} else {
							throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
						}
					})
					.then(data => {
						this.configuration = data.modules;
						this.features = data.features;

						this.ready = true;

						this.$nextTick(() => {
							this.configurationChanged = false;
						});
					})
					.catch(error => {
						this.handleError(error);
					});
		},
		/* ************************** */
		async saveConfiguration() {
			this.inProgress = true;
			await new Promise(resolve => setTimeout(resolve, 500));

			try {
				let acsrf_token = await get_acsrf_token();
				let payload = {
					acsrf_token: acsrf_token,
					modules: this.configuration
				};
				let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/server/${this.server_id}/radar`, {
					method: 'PATCH',
					body: JSON.stringify(payload),
					credentials: 'include'
				});
				if (response.ok) {
					this.$swal({
						icon: 'success',
						title: this.$t('server.view.configuration.save.success.title'),
						text: this.$t('server.view.configuration.save.success.text'),
					});
					this.configurationChanged = null;
					await this.getConfiguration();
				} else {
					if (response.status === 429) {
						this.$swal({
							icon: 'warning',
							text: this.$t('error.server.ratelimit.message'),
							title: this.$t('error.server.ratelimit.title')
						});
					} else
						throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
				}
			} catch (error) {
				console.log(`[ERROR] ${error}`);
				this.$swal({
					icon: 'error',
					text: this.$t('error.server.generic.message')
				});
			}
			this.inProgress = false;
		},
		scrollHandler() {
			if(!this.$refs.floatingContainer) return;

			if(window.pageYOffset > 70) {
				if(!this.$refs.floatingContainer.classList.contains('c-floating-side')) {
					this.$refs.floatingContainer.classList.add('c-floating-side');
				}
			} else {
				this.$refs.floatingContainer.classList.remove('c-floating-side');
			}
		}
	},
	mounted() {
		this.getConfiguration();

		window.addEventListener('scroll', this.scrollHandler);
	},
	destroyed() {
		window.removeEventListener('scroll', this.scrollHandler);
	},
	watch: {
		configuration: {
			deep: true,
			handler(newValue, oldValue) {
				if (!this.ready) return;
				if (this.configurationChanged === null) {
					this.configurationChanged = false;
				} else {
					this.configurationChanged = true;
				}
			}
		}
	},
	data() {
		return {
			window: window,
			Game: Game,
			ready: false,
			error: false,
			inProgress: false,
			configurationChanged: null,
			features: null,
			configuration: {

			},
		}
	}
};
</script>

<template>
	<div>
		<template v-if="ready">
			<div class="row">
				<div class="col-sm-12 d-lg-none">
					<button class="btn btn-block hard-border save-btn"
					        v-on:click="saveConfiguration()"
					        :disabled="inProgress"
					        :class="{
                    'disabled': inProgress,
                    'btn-success': configurationChanged === true,
                    'btn-primary': configurationChanged === false
                  }"
					>
						<half-circle-spinner
								v-if="inProgress"
								:animation-duration="1200"
								:size="16"
								class="align-middle d-inline-block"
						/>
						<template v-if="!inProgress">
							{{ $t('server.view.configuration.save.button') }}
						</template>
					</button>
					<div class="text-center small text-muted" v-if="configurationChanged === true">
						{{ $t('server.view.configuration.save.info') }}
					</div>
				</div>
				<div class="col-lg-9 col-sm-12 sm-spacer">
					<div>
						<div role="tablist">
							<!-- Radar Risk Indicators -->
							<b-card no-body class="settings-collapse hard-border">
								<a v-b-toggle.risk_indicators class="text-dark" href="javascript: void(0);">
									<b-card-header header-tag="header" role="tab">
										<h3 class="m-0 d-inline-flex">
											{{ $t('server.view.radar.risk_indicators.title') }}
										</h3>
										<div class="d-inline-flex float-right">
											<div class="btn btn-sm btn-dark">
												{{ $t('server.view.radar.expand') }}
											</div>
										</div>
									</b-card-header>
								</a>
								<b-collapse id="risk_indicators" accordion="configuration" role="tabpanel">
									<b-card-body>
										<b-card-text>
											<div class="alert alert-info d-flex">
												<h4 class="pb-0 mb-0 d-inline-block text-black align-self-center justify-content-center h-100">{{ $t('server.view.radar.risk_indicators.notice.text') }}</h4>
												<a class="btn btn-primary h5 ml-2 mb-0" href="https://help.cftools.com/en/cf-tools-cloud/player-risk" target="_blank">
													{{ $t('server.view.radar.risk_indicators.notice.button') }}
												</a>
											</div>
											<!-- Module toggle -->
											<div class="row">
												<div class="w-75">
													<div class="ml-4 mr-4">
														<h4>
															{{ $t('server.view.radar.risk_indicators.active.title') }}
														</h4>
														<span>
                              {{ $t('server.view.radar.risk_indicators.active.description') }}
                            </span>
													</div>
												</div>
												<div class="w-25">
													<div style="text-align: center;">
														<toggle-button v-model="configuration.risk_indicators.status"
														               :sync="true"
														               :color="{checked: '#34c38f', unchecked: '#f46a6a'}"
														               :height="40"
														               :width="80"
														               :labels="{checked: $t('terms.on'), unchecked: $t('terms.off')}"
														               style="font-size: 13px !important;"
														               class="m-0 mt-1 mb-1"
														/>
													</div>
												</div>
											</div>
											<!---------------------------------------->
										</b-card-text>
									</b-card-body>
								</b-collapse>
							</b-card>
							<!---------------------------------------->
						</div>
					</div>
				</div>
				<div class="col-lg-3 col-sm-12">
					<div class="" ref="floatingContainer">
						<button class="btn btn-block hard-border save-btn"
						        v-on:click="saveConfiguration()"
						        :disabled="inProgress"
						        :class="{
	                    'disabled': inProgress,
	                    'btn-success': configurationChanged === true,
	                    'btn-primary': configurationChanged === false
	                  }"
						>
							<half-circle-spinner
									v-if="inProgress"
									:animation-duration="1200"
									:size="16"
									class="align-middle d-inline-block"
							/>
							<template v-if="!inProgress">
								{{ $t('server.view.configuration.save.button') }}
							</template>
						</button>
						<div class="text-center small text-muted" v-if="configurationChanged === true">
							{{ $t('server.view.configuration.save.info') }}
						</div>
					</div>
				</div>
			</div>
		</template>
		<template v-else-if="error">
			<div class="row">
				<div class="col-lg-12 col-sm-12">
					<div class="card border border-danger">
						<div class="card-header bg-transparent border-danger">
							<h5 class="my-0 text-danger">
								<i class="far fa-exclamation-circle mr-3"></i>
								{{ $t('error.server.generic.title') }}
							</h5>
						</div>
						<div class="card-body pt-0">
							<h5 class="card-title mt-0"> {{ $t('error.server.generic.component') }}</h5>
						</div>
					</div>
				</div>
			</div>
		</template>
		<template v-else>
			<div class="row">
				<div class="col-lg-11">
					<!-- Content row one -->
					<div class="row">
						<div class="col">
							<div class="card">
								<div class="card-body">
									<div class="row">
										<div class="col-lg-4">
											<VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
										</div>
										<div class="col-lg-4">
											<VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
										</div>
										<div class="col-lg-4">
											<VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col">
							<div class="card">
								<div class="card-body">
									<div class="row">
										<div class="col-lg-4">
											<VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
										</div>
										<div class="col-lg-4">
											<VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
										</div>
										<div class="col-lg-4">
											<VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col">
							<div class="card">
								<div class="card-body">
									<div class="row">
										<div class="col-lg-4">
											<VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
										</div>
										<div class="col-lg-4">
											<VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
										</div>
										<div class="col-lg-4">
											<VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</template>
	</div>
</template>
